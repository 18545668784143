<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-card title="Busque um cliente 🔍">
        <b-card-text>No campo abaixo busque por: Email, Nome, Nickname</b-card-text>
        <b-form-input 
          icon-no-border  
          @keyup.enter.native="searchUser()" 
          placeholder="Digite e pressione ENTER" 
          v-model="searchQuery" 
          class="input-rounded-full" 
          icon="icon-search" 
          icon-pack="feather"
          :state="docState"
          aria-describedby="input-live-help input-live-feedback"
          id="live-query"
        />
        <b-form-invalid-feedback id="input-live-feedback">
          Pelo menos 3 letras
        </b-form-invalid-feedback>
      </b-card>
      <general-clients-list :searchedUsers="queryResult" :contextActions="['master-details']"/>
    </div>
    <div v-else>
      <logo-loader-animation/>
    </div>
  </div>
  
</template>

<script>
import { BCard, BCardText, BLink, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import GeneralClientsList from "@/components/clients/GeneralClientsList.vue"
import gql from 'graphql-tag'
import {_} from 'vue-underscore';
import LogoLoaderAnimation from "@/components/misc/LogoLoaderAnimation.vue"

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BFormInput,
    GeneralClientsList,
    BFormInvalidFeedback,
    underscore: _,
    LogoLoaderAnimation

  },
  data () {
    return {
      searchQuery: '',
      currentPage: 1,
      searchResults: [],
      queryResult:null,
      pageToSearch:1,
      pageInfo:{}
    }
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2 ? true : false
    }
  },
  methods:{
    searchUser(){
      if(this.searchQuery == '' || !document.getElementById('live-query').getAttribute("aria-invalid")){
        this.$apollo.query({
          query: gql`query($value: String,$code: String){
            master_user_documents_by_value(
              value : $value,
              code: $code
            ){
              value,
              user{
                id,
                username,
                status,
                created_at,
                documents{
                  id,
                  type,
                  value
                }
              },
          }
          }`,
          variables:{
              value: '%'+this.searchQuery+'%',
              code : '%'+JSON.parse(localStorage.getItem('userData')).inviteCode+'%'
          },
          client: "identityClient"
        }).then((data) => {
          this.queryResult = data.data.master_user_documents_by_value
          this.queryResult = _.pluck(this.queryResult, 'user');
        }).catch((error) => {
          console.error(error)
        })
      }
    },
    paginate(eV){
      this.pageToSearch = this.pageToSearch + eV;
      this.searchUser()
    }
  },
  created(){
    this.searchUser()
  }
}
</script>

<style>

</style>
